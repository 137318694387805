var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vx-row mb-12"}),_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.detailShow + ' vertical-divider' : '',
        _vm.detailHide,
      ]},[_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"thead"}),_c('template',{slot:"tbody"},_vm._l((_vm.responseData.creditNote),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',[[_c('vx-tooltip',{attrs:{"text":"View Credit Note"}},[_c('vs-button',{attrs:{"color":"primary","type":"line","icon-pack":"feather","icon":"icon-eye"},on:{"click":function($event){return _vm.handleView(tr)}}})],1)]],2),_c('vs-td',[_vm._v(" Credit Note Code : "+_vm._s(tr.Code)),_c('br'),_vm._v(" Debit Note Code : "+_vm._s(_vm.responseData.debitNote[indextr].Code)),_c('br'),_vm._v(" SOA Number : "+_vm._s(_vm.responseData.debitNote[indextr].SoaNumber)+" "),_c('br'),_vm._v(" Customer Return Code : "+_vm._s(tr.SalesReturnCode==""?"-":tr.SalesReturnCode)),_c('br'),_vm._v(" Customer Return Reference Code : "+_vm._s(_vm.responseData.salesReturn[indextr].SrReferenceCode)),_c('br'),_vm._v(" Type : "+_vm._s(tr.Sources == "Import Claim" ? tr.Type : _vm.getSalesReturnType(tr.SalesReturnID))+" "),(tr.SalesReturnCode=='' && tr.Sources != 'Import Claim')?[_vm._v(" Credit Note Manual ")]:_vm._e()],2),_c('vs-td',[_vm._v(" Code : "+_vm._s(tr.CustomerCode)),_c('br'),_vm._v(" Name : "+_vm._s(tr.CustomerName)),_c('br')]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.dateFormat(tr.CreatedAt))+" ")]),_c('vs-td',[_vm._v(" Initial Invoice : "+_vm._s(_vm.initialInvoice[indextr].Code)+" "),_c('br'),_vm._v(" Invoice : "+_vm._s(tr.InvoiceNumber != '' ? tr.InvoiceNumber : tr.InvoiceCode)+" "),_c('br'),_vm._v(" Note : "+_vm._s(tr.Note)+" "),_c('br'),_vm._v(" Total Value CN : "+_vm._s(tr.TotalValue.toFixed(2) .toString() .replace(/\B(?=(\d{3})+(?!\d))/g, ','))+" ")])],1)}),1)],2)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('div',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.closeDetail}},[_vm._v("Close")])],1),_c('form-view',{attrs:{"selected":_vm.selectedData},on:{"close":_vm.closeDetail}})],1)]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }