<template>
  <div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Credit Note Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ selected.Code }}
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer Return</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ selected.SalesReturnCode == "" ? "-" : selected.SalesReturnCode }}
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ selected.CustomerCode }} - {{ selected.CustomerName }}
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Invoice</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full" v-if="payment.ID != 0">
        {{ payment.InvoiceCode }}
      </div>
      <div class="vx-col sm:w-2/3 w-full" v-if="selected.Type == 'Claim'">
        <multiselect
          class="selectExample"
          v-model="invoiceData.selected"
          :options="invoiceData.options"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          placeholder="Type to search"
          track-by="InvoiceReferenceCode"
          label="InvoiceReferenceCode"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{
                props.option.InvoiceReferenceCode
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{
                props.option.InvoiceReferenceCode
              }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>

    <vs-divider style="width: 100%; margin-left: 2%"
      >Total Credit Note</vs-divider
    >

    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>DPP</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ formatPrice(selected.DPP.toString()) }}
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Tax</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ formatPrice(selected.TaxValue.toString()) }}
      </div>
    </div>
    <template v-if="selected.SalesReturnCode == ''">
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Total Value</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          {{ formatPrice(selected.TotalValue.toString()) }}
        </div>
      </div>
    </template>

    <div
      class="vx-row margin-btm"
      style="width: 100%; padding-bottom: 20px"
      v-if="selected.Type == 'Claim'"
    >
      <div class="vx-col sm:w-1/3 w-full"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
          Update
        </vs-button>
      </div>
    </div>

    <vs-divider
      v-if="selected.SalesReturnCode != ''"
      style="width: 100%; margin-left: 2%"
      >List Item</vs-divider
    >
    <table v-if="selected.SalesReturnCode != ''" class="table">
      <thead>
        <th>Item Code</th>
        <th>Item Name</th>
        <th>Qty</th>
        <th>CR Value</th>
        <th>CN Value</th>
      </thead>
      <template v-for="line in lines">
        <tr v-bind:key="line.ID">
          <td>{{ line.ItemCode }}</td>
          <td>{{ line.ItemName }}</td>
          <td>{{ line.Qty }}</td>
          <td>
            Price Total : {{ formatPrice(line.PriceSo + line.ReturnDiscount)
            }}<br />
            Discount : {{ formatPrice(line.ReturnDiscount) }}<br />
            Subtotal Total : {{ formatPrice(line.PriceSo.toString()) }}<br />
            Tax Amount : {{ formatPrice(line.ReturnTax.toString()) }}<br />
            Total : {{ formatPrice(line.ReturnTotal.toString()) }}<br />
          </td>
          <td>
            Price Total : {{ formatPrice(line.PriceSo + line.ReturnDiscount)
            }}<br />
            Discount : {{ formatPrice(line.ReturnDiscount) }}<br />
            Subtotal Total : {{ formatPrice(line.PriceCn.toString()) }}<br />
            Tax Amount : {{ formatPrice(line.TaxAmount.toString()) }}<br />
            Total : {{ formatPrice(line.Total.toString()) }}<br />
          </td>
        </tr>
      </template>
      <tr>
        <td></td>
        <td></td>
        <td>{{ totalQty }}</td>
        <td>
          <font style="font-weight: bold">Price Total</font> :
          {{ formatPrice(this.ReturnPriceTotal) }}<br />
          <font style="font-weight: bold">Discount</font> :
          {{ formatPrice(this.ReturnDiscount) }}<br />
          <font style="font-weight: bold">Subtotal Total</font> :
          {{ formatPrice(this.ReturnSubtotalTotal.toString()) }}<br />
          <font style="font-weight: bold">Tax Amount</font> :
          {{ formatPrice(this.ReturnTaxAmount.toString()) }}<br />
          <font style="font-weight: bold">Total</font> :
          {{ formatPrice(this.ReturnTotal.toString()) }}<br />
        </td>
        <td>
          <font style="font-weight: bold">Price Total</font> :
          {{ formatPrice(this.PriceTotal) }}<br />
          <font style="font-weight: bold">Discount</font> :
          {{ formatPrice(this.Discount) }}<br />
          <font style="font-weight: bold">Subtotal Total</font> :
          {{ formatPrice(this.SubtotalTotal.toString()) }}<br />
          <font style="font-weight: bold">Tax Amount</font> :
          {{ formatPrice(this.TaxAmount.toString()) }}<br />
          <font style="font-weight: bold">Total</font> :
          {{ formatPrice(this.Total.toString()) }}<br />
        </td>
      </tr>
    </table>
    <div
      v-if="selected.SalesReturnCode != '' && this.btnReversal == false"
      class="vx-col w-full"
    >
      <br />
      <vs-button
        :disabled="reverseButtonDisabled"
        color="green"
        class="mb-2"
        v-on:click="handleReversal"
        >Reversal approve</vs-button
      >
    </div>
    <div
      v-if="selected.SalesReturnCode != '' && this.btnReversal == false"
      class="vx-col w-full"
    >
      <br />
      <vs-button
        :disabled="reverseButtonDisabled"
        color="red"
        class="mb-2"
        v-on:click="handleReversalCancel"
        >Reversal Cancel</vs-button
      >
    </div>
  </div>
</template>
<script>
import { dataCreditNote } from "../../../../../../services/api/credit_note";
import moment from "moment";
export default {
  props: {
    selected: Object,
    option: Object,
  },
  components: {},
  data() {
    return {
      btnReversal: false,
      lines: [],
      payment: {},
      totalQty: 0,
      totalSoValue: 0,
      totalCnValue: 0,
      ReturnPriceTotal: 0,
      PriceTotal: 0,
      ReturnDiscount: 0,
      Discount: 0,
      ReturnSubtotalTotal: 0,
      SubtotalTotal: 0,
      ReturnTaxAmount: 0,
      TaxAmount: 0,
      ReturnTotal: 0,
      Total: 0,
      invoiceData: {
        selected: null,
        options: [],
      },
      responseData: {},
      reverseButtonDisabled: false,
    };
  },
  computed: {},
  watch: {
    "selected.ID"() {
      this.getInvoiceData(this.selected.CustomerCode);
      this.reloadData();
      this.selected.TotalValue = this.formatPrice(this.selected.TotalValue);
      this.selected.DPP = this.formatPrice(this.selected.DPP);
      this.selected.TaxValue = this.formatPrice(this.selected.TaxValue);
      console.log(this.selected);
    },
  },
  mounted() {
    this.getInvoiceData(this.selected.CustomerCode);
    this.reloadData();
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("DD/MM/YYYY hh:mm");
      }
    },
    addValidate(ID) {
      console.log(this.validate.length);
      if (this.validate.includes(ID)) {
        this.validate.splice(this.validate.indexOf(ID), 1);
      } else {
        this.validate.push(ID);
      }
    },
    addAllValidate() {
      console.log(this.data, this.validateAll);
      if (this.validateAll == false) {
        for (var i in this.data) {
          if (!this.validate.includes(this.data[i].ID)) {
            this.validate.push(this.data[i].ID);
          }
        }
      } else {
        this.validate = [];
      }
    },
    reloadData() {
      this.$vs.loading();
      if (this.selected.Type == "Claim" && this.selected.InvoiceNumber != "") {
        this.setInvoiceSelected(this.selected.InvoiceNumber);
      }
      const creditNote = dataCreditNote(this.selected);
      creditNote.then((r) => {
        this.$vs.loading.close();
        console.log(r);
        this.lines = [];
        this.ReturnPriceTotal = 0;
        this.PriceTotal = 0;
        this.ReturnDiscount = 0;
        this.Discount = 0;
        this.ReturnSubtotalTotal = 0;
        this.SubtotalTotal = 0;
        this.ReturnTaxAmount = 0;
        this.TaxAmount = 0;
        this.ReturnTotal = 0;
        this.Total = 0;
        r.data.creditNoteLine.map((v) => {
          // v.PriceSo = v.PriceSo.toString().split(".")[0]
          // v.TotalSo = v.PriceSo //parseFloat(v.PriceSo * v.Qty) +  parseFloat(v.PriceSo * v.Qty * v.TaxRate / 100)
          // this.totalCnValue = this.totalCnValue + v.TotalSo
          // this.totalSoValue = this.totalSoValue + v.Total
          // v.TotalSo = v.TotalSo.toString().replace('.', ',')
          // v.Total = v.Total.toString().replace('.', ',')
          this.ReturnPriceTotal =
            this.ReturnPriceTotal + (v.PriceSo + v.ReturnDiscount);
          this.PriceTotal = this.PriceTotal + (v.PriceCn + v.ReturnDiscount);
          this.ReturnDiscount = this.ReturnDiscount + v.ReturnDiscount;
          this.Discount = this.Discount + v.ReturnDiscount;
          this.ReturnSubtotalTotal = this.ReturnSubtotalTotal + v.PriceSo;
          this.SubtotalTotal = this.SubtotalTotal + v.PriceCn;
          this.ReturnTaxAmount = this.ReturnTaxAmount + v.ReturnTax;
          this.TaxAmount = this.TaxAmount + v.TaxAmount;
          this.ReturnTotal = this.ReturnTotal + v.ReturnTotal;
          this.Total = this.Total + v.Total;
          this.totalQty = this.totalQty + v.Qty;
          this.lines.push(v);
        });
        this.payment = r.data.payment;
        this.totalCnValue = this.totalCnValue.toString().replace(".", ",");
        this.totalSoValue = this.totalSoValue.toString().replace(".", ",");
        console.log(this.lines, "lines");
      });
    },
    handleView(selected) {
      console.log(selected);
      this.$vs.loading();
    },
    handleDelete(selected) {
      console.log(selected);
    },
    formatPrice(angka, prefix = "") {
      const amount = parseFloat(angka).toFixed(2);
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getInvoiceData(CustomerCode) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/invoice/reference", {
          params: {
            order: "asc",
            sort: "invoice_reference_code",
            customer_code: CustomerCode,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.invoiceData.options = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setInvoiceSelected(InvoiceReferenceCode) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/invoice/reference/" + InvoiceReferenceCode)
        .then((resp) => {
          if (resp.status == "success") {
            this.invoiceData.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleSubmit() {
      console.log(this.selected);
      this.putData(this.selected.ID);
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("closeDetail", true);
    },
    handleReversal() {
      console.log(this.selected);
      const params = {
        id: this.selected.ID,
        statusCN: 6,
      };
      this.btnReversal = true;
      this.reverseButtonDisabled = true;
      console.log("reverse tombol gesss", this.reverseButtonDisabled);
      this.$vs.loading();
      // this.reloadData();
      //console.log(params)
      this.$http
        .post("/api/v1/credit-note/ConfirmReversal", params)
        .then((result) => {
          console.log(result);
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed, " + result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.reverseButtonDisabled = false;
          this.closeDetail();
        })
        .catch((e) => {
          this.reverseButtonDisabled = false;
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        });
    },
    handleReversalCancel() {
      console.log(this.selected);
      const params = {
        id: this.selected.ID,
        statusCN: 2,
      };
      this.btnReversal = true;
      this.reverseButtonDisabled = true;
      this.$vs.loading();
      //console.log(params)
      this.$http
        .post("/api/v1/credit-note/ConfirmReversal", params)
        .then((result) => {
          console.log(result);
          // this.reloadData();
          this.$vs.loading.close();

          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed, " + result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          // this.reloadData();
          this.reverseButtonDisabled = false;
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          this.reverseButtonDisabled = false;
        });
    },
    paramData() {
      return {
        invoice_number: this.invoiceData.selected.InvoiceReferenceCode,
      };
    },
    putData(id) {
      this.$vs.loading();
      this.$http
        .put("/api/v1/credit-note/invoice/" + id, this.paramData())
        .then((resp) => {
          if (resp.code == 200) {
            this.closeDetail();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    closeDetail() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
